import { HiPlus } from "@react-icons/all-files/hi2/HiPlus"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { cn } from "../utils"

type Props = {
  slug: string
  series: Queries.SeriesFragment
}

const ArticleSeriesCard = ({ slug, series }: Props) => {
  const allMdx = (series.allMdx ?? []).filter(
    (mdx) =>
      mdx !== null &&
      mdx.fields?.isFuture === false &&
      mdx.frontmatter?.published === true
  )
  const currentMdxIndex = allMdx.findIndex(
    (mdx) => mdx?.frontmatter?.slug === slug
  )
  const totalOfMdx = allMdx.length

  return (
    <section className="not-prose mb-4 w-full divide-y divide-gray-200 rounded border border-gray-200 bg-primary">
      <details className="group p-4">
        <summary className="relative cursor-pointer list-none divide-y-8 pr-8 text-xl font-semibold text-primary focus-visible:outline-none group-hover:text-pink-600 group-hover:dark:text-pink-400 [&::-webkit-details-marker]:hidden">
          {series.name} (Parte {currentMdxIndex + 1} de {totalOfMdx})
          <HiPlus className="absolute right-0 top-1 h-6 w-6 shrink-0 stroke-gray-700 transition-transform duration-300 group-open:rotate-45" />
        </summary>
        {allMdx.map((mdx, index) => {
          return (
            <GatsbyLink
              key={index}
              to={`/blog/${mdx?.frontmatter?.slug}`}
              className="group/item flex items-center space-x-2"
            >
              <span
                className={cn(
                  "relative inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-full text-sm text-white",
                  currentMdxIndex === index
                    ? "bg-pink-600"
                    : "bg-gray-500 group-hover/item:bg-pink-600 dark:bg-gray-800 group-hover/item:dark:bg-pink-400"
                )}
              >
                {index + 1}
              </span>

              <p
                className={cn(
                  "py-3 text-base",
                  currentMdxIndex === index
                    ? "font-semibold text-pink-600 dark:text-pink-400"
                    : "text-primary group-hover/item:text-pink-600 group-hover/item:dark:text-pink-400"
                )}
              >
                {mdx?.frontmatter?.title}
              </p>
            </GatsbyLink>
          )
        })}
      </details>
    </section>
  )
}

export default ArticleSeriesCard
