import React from "react"
import { useSiteMetadata } from "../hooks"

export type PageContentType = "website" | "article" | "profile"

type Props = {
  title?: string
  description?: string
  pathName?: string
  authorTwitterUsername?: string
  imageSrc?: string
  imageAlt?: string
  pageContentType?: PageContentType
}

const Seo = ({
  title,
  description,
  pathName,
  authorTwitterUsername,
  imageSrc,
  imageAlt,
  pageContentType
}: Props): JSX.Element => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
    social,
  } = useSiteMetadata()

  const titleSEO = title ? `${title} | ${defaultTitle}` : defaultTitle

  const seo = {
    siteName: defaultTitle,
    title: titleSEO,
    description: description || defaultDescription,
    url: `${siteUrl}${pathName || ``}`,
    authorTwitterUsername: authorTwitterUsername || social.twitter,
    image: imageSrc
      ? `${siteUrl}${imageSrc}`
      : `https://dummyimage.com/1200x630/6988c5/fff.png&text=${encodeURIComponent(
          title ?? defaultTitle
        )}`,
    imageAlt: imageAlt ?? `Una imagen con el texto: ${titleSEO}`,
    pageContentType: pageContentType ?? "website"
  }

  return (
    <>
      <html lang="es" />
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <link rel="canonical" key={seo.url} href={seo.url} />

      {/* Facebook Meta Tags */}
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content={seo.pageContentType} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:image:alt" content={seo.imageAlt} />
      <meta property="og:site_name" content={seo.siteName} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta
        name="twitter:image:src"
        content={seo.image}
      />
      <meta name="twitter:image:alt" content={seo.imageAlt} />

      {authorTwitterUsername &&
        <meta name="twitter:creator" content={authorTwitterUsername} />
      }

      <meta name="twitter:site" content={social.twitter} />
    </>
  )
}

export default Seo
