import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
import { FaSquareXTwitter } from "@react-icons/all-files/fa6/FaSquareXTwitter"
import { HiEnvelope } from "@react-icons/all-files/hi2/HiEnvelope"
import React from "react"

type Props = {
  title: string
  url: string
}

const SocialShareList = ({ title, url }: Props): JSX.Element => {
  return (
    <div className="flex items-center space-x-4">
      <a
        target="_blank"
        rel="noreferrer noopener"
        title="Compartir artículo en Facebook"
        aria-label="Compartir artículo en Facebook"
        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
      >
        <FaFacebookSquare className="h-8 w-8 hover:text-[#1877f2]" />
      </a>

      <a
        target="_blank"
        rel="noreferrer noopener"
        title="Compartir artículo en Twitter"
        aria-label="Compartir artículo en Twitter"
        href={`https://twitter.com/intent/tweet?via=asynclearn&text=${title}&url=${url}`}
      >
        <FaSquareXTwitter className="h-8 w-8 hover:text-[#1d9bf0]" />
      </a>

      <a
        target="_blank"
        rel="noreferrer noopener"
        title="Compartir artículo en Linkedin"
        aria-label="Compartir artículo en Linkedin"
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
      >
        <FaLinkedin className="h-8 w-8 hover:text-[#0077b5]" />
      </a>

      <a
        target="_blank"
        rel="noreferrer noopener"
        title="Compartir artículo por email"
        aria-label="Compartir artículo por email"
        href={`mailto:?subject=${title}&body=${url}`}
      >
        <HiEnvelope className="h-8 w-8 hover:text-danube-500" />
      </a>
    </div>
  )
}

export default SocialShareList
