import { graphql, useStaticQuery } from "gatsby"
import { Site, SiteMetadata } from "../queries"

export const useSiteMetadata = (): SiteMetadata => {
  const site = useStaticQuery<Site>(graphql`
    query SiteInfo {
      site {
        ...SiteInformation
      }
    }
  `)

  return site.site.siteMetadata
}
