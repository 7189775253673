import { HiOutlineMoon } from "@react-icons/all-files/hi2/HiOutlineMoon"
import { HiOutlineSun } from "@react-icons/all-files/hi2/HiOutlineSun"
import React from "react"
import { ThemeContext } from "./themeContext"

const ThemeToggle = (): JSX.Element => {
  const themeProps = React.useContext(ThemeContext)

  return (
    <div className="inline-flex items-center rounded-3xl bg-secondary p-[1px]">
      <button
        className="cursor-pointer rounded-3xl bg-white fill-current p-2 text-black dark:bg-transparent dark:text-white "
        aria-label="Set light theme"
        onClick={() => themeProps?.toggle()}
      >
        <HiOutlineSun className="h-6 w-6" />
      </button>

      <button
        className="cursor-pointer rounded-3xl fill-current p-2 dark:bg-white dark:text-black"
        aria-label="Set dark theme"
        onClick={() => themeProps?.toggle()}
      >
        <HiOutlineMoon className="h-6 w-6" />
      </button>
    </div>
  )
}

export default ThemeToggle
