import React, { PropsWithChildren } from "react"
import { cn } from "../utils"

type Props = {
  color?: string
  isLarge?: boolean
  isClickable?: boolean
}

const Badge = ({
  color,
  isLarge,
  isClickable,
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <span
      className={cn(
        "inline-flex items-center rounded-md px-2 py-1 font-medium text-white",
        isLarge ? "text-2xl" : "text-xs",
        isClickable &&
          "transition hover:scale-110 hover:shadow-xl focus:outline-none focus:ring"
      )}
      style={color ? { backgroundColor: color } : { backgroundColor: "#000" }}
    >
      {children}
    </span>
  )
}

export default Badge
