import { Tag } from "../queries"

function mapTag(
  id: string,
  yamlId: string | null,
  name: string | null,
  color: string | null,
  slug: string | null | undefined
): Tag {
  const tag: Tag = {
    id: id ?? "",
    yamlId: yamlId ?? "",
    name: name ?? "",
    color: color ?? "",
    fields: {
      slug: slug ?? "",
    },
  }
  return tag
}

export default mapTag
