import React, { createContext } from "react"
import { useThemeMode } from "../hooks"

export type ThemeContextProps = {
  isDarkMode: boolean
  toggle: () => void
}

const ThemeContext = createContext<ThemeContextProps | null>(null)

type ThemeProviderProps = {
  children: React.ReactNode
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { isDarkMode, toggle } = useThemeMode()

  return (
    <ThemeContext.Provider value={{ isDarkMode: isDarkMode, toggle: toggle }}>
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeProvider }
