import { HiOutlineFaceFrown } from "@react-icons/all-files/hi2/HiOutlineFaceFrown"
import { HiMagnifyingGlass } from "@react-icons/all-files/hi2/HiMagnifyingGlass"
import { Command } from "cmdk"
import { Link, navigate } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { useOnClickOutside, useToggle } from "usehooks-ts"
import { Edge } from "../queries"

type Props = {
  allMdx: Edge[]
}

const Search = ({ allMdx }: Props) => {
  const [isOpen, toggle] = useToggle()
  const [search, setSearch] = useState("")
  const ref = useRef(null)

  const handleNavigate = (event: KeyboardEvent, slug: string) => {
    if (event.key === "Enter") {
      navigate(slug)
    }
  }

  const handleClick = () => {
    toggle()
  }

  const handleClickOutside = () => {
    toggle()
  }

  const handleDown = (event) => {
    if (event.key === "k" && event.metaKey) {
      toggle()
    }
    if (event.key === "Enter" && event.target.value === "esc") {
      toggle()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleDown)
    return () => document.removeEventListener("keydown", handleDown)
  }, [])

  useOnClickOutside(ref, handleClickOutside)

  return (
    <>
      <button
        type="button"
        aria-label="Quick search"
        onClick={handleClick}
        className="rounded-md p-2 hover:bg-danube-600 hover:text-white"
      >
        <HiMagnifyingGlass className="h-6 w-6" />
      </button>

      <Command.Dialog
        open={isOpen}
        onOpenChange={toggle}
        label="Search"
        role="button"
        className="fixed left-0 top-0 z-10 h-screen w-screen cursor-default bg-gray-500 bg-opacity-25 backdrop-blur-sm transition-opacity dark:bg-black dark:bg-opacity-25"
      >
        <div className="flex h-full w-full items-start justify-center p-6 sm:items-center">
          <div
            ref={ref}
            className="min-h-[500px] w-full rounded-xl bg-primary py-6 shadow-2xl ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-5 sm:max-w-[800px]"
          >
            <Command
              value={search}
              onValueChange={setSearch}
              className="grid gap-2"
            >
              <div className="flex items-center gap-2 border-b-[1px] border-[#f3f4f6] px-6 pb-4 dark:border-danube-900">
                <div className="relative basis-full">
                  <HiMagnifyingGlass className="pointer-events-none absolute h-5 w-5 text-primary" />
                  <Command.Input
                    placeholder="Buscar..."
                    className="w-full border-0 bg-transparent p-0 pl-8 text-primary placeholder:text-gray-400 focus:outline-none focus:ring-0"
                  />
                </div>
                <button
                  className="rounded-md bg-danube-500 px-2 py-1 text-xs uppercase text-white transition-all duration-300 hover:bg-danube-600"
                  aria-label="esc"
                  value="esc"
                  onClick={handleClick}
                >
                  cancelar
                </button>
              </div>

              <Command.List className="px-6">
                <Command.Empty className="flex h-[500px] items-center justify-center px-4 text-center">
                  <span className="text-lg font-semibold text-primary">
                    <HiOutlineFaceFrown className="mx-auto h-10 w-10" />
                    No se han encontrado resultados.
                  </span>
                </Command.Empty>

                <Command.Group className="h-[500px] overflow-auto">
                  {allMdx.map(({ node }, index) => {
                    const {
                      frontmatter: { title, date, slug },
                    } = node
                    const path = `/blog/${slug}`

                    return (
                      <Command.Item
                        key={index}
                        value={title}
                        disabled={true}
                        className="my-2 rounded-md bg-gray-100 hover:bg-danube-600 dark:bg-[#121c31] dark:hover:bg-danube-600"
                        onKeyDown={(event) => handleNavigate(event, path)}
                      >
                        <Link
                          to={path}
                          onClick={handleClick}
                          className="group flex flex-col px-4 py-3 text-primary hover:text-white"
                        >
                          <span className="text-[0.6rem] text-primary group-hover:text-white">
                            {date}
                          </span>
                          {title}
                        </Link>
                      </Command.Item>
                    )
                  })}
                </Command.Group>
              </Command.List>
            </Command>
          </div>
        </div>
      </Command.Dialog>
    </>
  )
}

export default Search
