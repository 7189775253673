import { Link as GatsbyLink } from "gatsby"
import React from "react"
import logo from "../images/logo.svg"
import logoDarkMode from "../images/logo_darkmode.svg"
import ThemeToggle from "./themeToggle"
import Search from "./search"
import { Edge } from "../queries"

type HeaderProps = {
  title: string
  allMdx: Edge[]
}

const Header = ({ title, allMdx }: HeaderProps): JSX.Element => {
  return (
    <header className="text-primary">
      <div className="container mx-auto flex flex-col flex-wrap items-center p-5 md:flex-row">
        <GatsbyLink
          to="/"
          className="mb-4 flex items-center font-medium text-gray-900 md:mb-0"
        >
          <img
            src={logo}
            alt="AsyncLearn logo"
            className="block h-10 w-10 dark:hidden"
          />
          <img
            src={logoDarkMode}
            alt="AsyncLearn logo"
            className="hidden h-10 w-10 dark:block"
          />

          <span className="ml-3 text-3xl text-primary">{title}</span>
        </GatsbyLink>

        <nav
          aria-label="Main navigation"
          className="mb-4 flex flex-wrap items-center justify-center text-lg md:mb-0 md:ml-auto md:mr-auto"
        >
          <GatsbyLink
            to="/"
            className="mr-5 tracking-wide hover:text-gray-900 dark:hover:text-white"
          >
            Inicio
          </GatsbyLink>
          <GatsbyLink
            to="/blog/tags"
            className="mr-5 tracking-wide hover:text-gray-900 dark:hover:text-white"
          >
            Categorías
          </GatsbyLink>
        </nav>

        <div className="space-x-10 md:space-x-5">
          <Search allMdx={allMdx} />
          <ThemeToggle />
        </div>
      </div>
    </header>
  )
}

export default Header
