import React from "react"
import { Highlight, themes } from "prism-react-renderer"
import { copyToClipboard } from "../utils"
import { HiOutlineClipboard } from "@react-icons/all-files/hi2/HiOutlineClipboard"
import { HiCheck } from "@react-icons/all-files/hi2/HiCheck"

type Props = {
  code: string
  className: string
}

const PrismSyntaxHighlight = ({ code, className }: Props) => {
  const language = className.replace(/language-/gm, "")
  const [isCopied, setIsCopied] = React.useState(false)

  const handleCopyClick = () => {
    copyToClipboard(code)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 3000)
  }

  return (
    <div className="relative">
      <button
        className="absolute right-4 top-4 rounded border border-transparent px-2.5 py-1.5 text-xs font-medium text-white opacity-50 shadow-sm hover:border-white hover:opacity-100"
        onClick={handleCopyClick}
        aria-label="Copy code"
      >
        <span>
          {isCopied ? (
            <HiCheck className="h-6 w-6" />
          ) : (
            <HiOutlineClipboard className="h-6 w-6" />
          )}
        </span>
      </button>

      <Highlight code={code} language={language} theme={themes.vsDark}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={className} style={style}>
            {tokens.slice(0, -1).map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </div>
  )
}

export default PrismSyntaxHighlight
