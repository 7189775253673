import { GatsbyBrowser } from "gatsby"
import { Prism } from "prism-react-renderer"
import React from "react"
import { ThemeProvider } from "./src/components"
import "./src/styles/global.css"

(typeof global !== "undefined" ? global : window).Prism = Prism
require("prismjs/components/prism-swift")

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return <ThemeProvider>{element}</ThemeProvider>
}
