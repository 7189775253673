import React, { PropsWithChildren } from "react"
import { Link as GatsbyLink } from "gatsby"
import { usePagination, DOTS } from "../hooks"
import { HiChevronLeft } from "@react-icons/all-files/hi2/HiChevronLeft"
import { HiChevronRight } from "@react-icons/all-files/hi2/HiChevronRight"
import { cn } from "../utils"

type Props = {
  path: string
  totalCount: number
  siblingCount?: number
  currentPage: number
  pageSize: number
}

const Pagination = ({
  path,
  currentPage,
  totalCount,
  siblingCount = 1,
  pageSize,
}: Props): JSX.Element => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  if (currentPage === 0 || paginationRange.length < 2) {
    return <></>
  }

  const onPageChange = (pageNumber: number | string) => {
    return pageNumber == 1 ? path : `${path}page/${pageNumber}`
  }
  const onPageNext = onPageChange(currentPage + 1)
  const onPagePrevious = onPageChange(currentPage - 1)

  const isFirstPage = currentPage === 1
  const lastPage = paginationRange[paginationRange.length - 1]
  const isLastPage = currentPage === lastPage

  return (
    <div className="flex items-center justify-center px-4 pb-3 pt-6 sm:px-6 sm:pt-12">
      <SmallNavDiv isFirstOrLastPage={isFirstPage || isLastPage}>
        {!isFirstPage && (
          <SmallNavButton to={onPagePrevious}>Anterior</SmallNavButton>
        )}
        {!isLastPage && (
          <SmallNavButton to={onPageNext} className="ml-3">
            Siguiente
          </SmallNavButton>
        )}
      </SmallNavDiv>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-center">
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            {!isFirstPage && <ArrowButton to={onPagePrevious} type="left" />}
            {paginationRange.map((pageNumber) => {
              if (pageNumber === DOTS) {
                return (
                  <span
                    key={DOTS}
                    className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0 dark:text-white"
                  >
                    ...
                  </span>
                )
              }

              return (
                <NumberLink
                  key={pageNumber}
                  to={onPageChange(pageNumber)}
                  selected={pageNumber === currentPage}
                >
                  {pageNumber}
                </NumberLink>
              )
            })}
            {!isLastPage && <ArrowButton to={onPageNext} type="right" />}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Pagination

const SmallNavDiv = ({
  isFirstOrLastPage,
  children,
}: PropsWithChildren<{ isFirstOrLastPage: boolean }>) => {
  return (
    <div
      className={cn(
        "mt-4 flex flex-1 sm:hidden",
        isFirstOrLastPage ? "justify-center" : "justify-between"
      )}
    >
      {children}
    </div>
  )
}

const SmallNavButton = ({
  to,
  children,
  className,
}: PropsWithChildren<{
  to: string
  className?: string
}>) => {
  return (
    <GatsbyLink
      to={to}
      className={cn(
        "relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50  dark:bg-transparent dark:text-white dark:hover:bg-danube-900",
        className
      )}
    >
      {children}
    </GatsbyLink>
  )
}

const NumberLink = ({
  to,
  selected,
  children,
}: PropsWithChildren<{ to: string; selected?: boolean }>) => {
  return (
    <GatsbyLink
      to={to}
      className={cn(
        "relative inline-flex items-center px-4 py-2 text-sm font-semibold focus:z-20",
        selected
          ? "z-10 bg-danube-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-danube-600 dark:ring-1 dark:ring-inset dark:ring-gray-300"
          : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0 dark:text-white dark:hover:bg-danube-900"
      )}
    >
      {children}
    </GatsbyLink>
  )
}

type ArrowLinkType = "left" | "right"

type ArrowType = {
  to: string
  type: ArrowLinkType
}

const ArrowLink = ({
  to,
  type,
  children,
}: PropsWithChildren<{ to: string; type: ArrowLinkType }>) => {
  return (
    <GatsbyLink
      to={to}
      className={cn(
        "relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 dark:text-white dark:hover:bg-danube-900",
        type == "left" ? "rounded-l-md" : "rounded-r-md"
      )}
    >
      {children}
    </GatsbyLink>
  )
}

const ArrowButton = ({ to, type }: ArrowType): JSX.Element => {
  return (
    <ArrowLink to={to} type={type}>
      {type == "left" ? (
        <>
          <span className="sr-only">Anterior</span>
          <HiChevronLeft className="h-5 w-5" aria-hidden="true" />
        </>
      ) : (
        <>
          <span className="sr-only">Siguiente</span>
          <HiChevronRight className="h-5 w-5" aria-hidden="true" />
        </>
      )}
    </ArrowLink>
  )
}
