import { graphql, useStaticQuery } from "gatsby"
import { AllMdxData } from "../queries"

export const useAllMdx = () => {
  const data = useStaticQuery<AllMdxData>(graphql`
    query AllMdxStatic($skip: Int, $limit: Int) {
      ...AllMdx
    }
  `)

  return data.allMdx.edges
}
