import React from "react"
import { useAllMdx, useSiteMetadata } from "../hooks"
import Footer from "./footer"
import Header from "./header"

type HomeProps = {
  children: React.ReactNode
}

const Layout = ({ children }: HomeProps): JSX.Element => {
  const { title, description } = useSiteMetadata()
  const allMdx = useAllMdx()

  return (
    <div className="flex h-screen min-h-screen flex-col antialiased">
      <Header title={title} allMdx={allMdx} />

      <main>{children}</main>

      <Footer title={title} description={description} />
    </div>
  )
}

export default Layout
