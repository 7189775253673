import React, { PropsWithChildren } from "react"

type Props = {
  type: QuoteType
}

export type QuoteType = "info" | "warning" | "error" | "success"

const Quote = (props: PropsWithChildren<Props>): JSX.Element => {
  const blockquote = (): JSX.Element => {
    switch (props.type) {
      case "error":
        return (
          <blockquote className="my-6 border-l-4 border-red-600 bg-red-600/10 pl-4 font-medium dark:bg-red-600/20 [&_a]:underline">
            {props.children}
          </blockquote>
        )
      case "info":
        return (
          <blockquote className="my-6 border-l-4 border-sky-600 bg-sky-600/10 pl-4 font-medium dark:bg-sky-600/20 [&_a]:underline">
            {props.children}
          </blockquote>
        )
      case "success":
        return (
          <blockquote className="my-6 border-l-4 border-green-600 bg-green-600/10 pl-4 font-medium dark:bg-green-600/20 [&_a]:underline">
            {props.children}
          </blockquote>
        )
      case "warning":
        return (
          <blockquote className="my-6 border-l-4 border-yellow-600 bg-yellow-600/10 pl-4 font-medium dark:bg-yellow-600/20 [&_a]:underline">
            {props.children}
          </blockquote>
        )
      default:
        return <blockquote>{props.children}</blockquote>
    }
  }

  return <div className="not-prose">{blockquote()}</div>
}

export default Quote
