import React from "react"
import { HiCheck } from "@react-icons/all-files/hi2/HiCheck"
import { HiCalendarDays } from "@react-icons/all-files/hi2/HiCalendarDays"
import { HiNoSymbol } from "@react-icons/all-files/hi2/HiNoSymbol"
import { HiPencil } from "@react-icons/all-files/hi2/HiPencil"

type SubscribeSectionProps = {
  description: string
}

const SubscribeSection = ({
  description,
}: SubscribeSectionProps): JSX.Element => {
  return (
    <div className="relative isolate mt-2 overflow-hidden border-b border-gray-200 py-8 dark:border-danube-900 lg:mt-5">
      <div className="container mx-auto px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight dark:text-white sm:text-4xl">
              Subscríbete a nuestro Newsletter
            </h2>
            <p className="mt-4 text-lg leading-8 dark:text-gray-300">
              {description}
            </p>
            <form
              action="https://asynclearn.us11.list-manage.com/subscribe/post?u=37c43fca48bde7debeba695b7&amp;id=a13445c3ca&amp;f_id=009ea8e0f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              target="_blank"
              noValidate
            >
              <div className="mt-6 flex max-w-md gap-x-4">
                <label htmlFor="mce-EMAIL" className="sr-only">
                  Email address
                </label>
                <input
                  id="mce-EMAIL"
                  name="EMAIL"
                  type="email"
                  autoComplete="email"
                  required
                  className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-danube-500 dark:text-white dark:ring-white/10 sm:text-sm sm:leading-6"
                  placeholder="Introduce tu correo electrónico"
                />
                <button
                  type="submit"
                  className="flex-none rounded-md bg-danube-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-danube-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-danube-500"
                >
                  Subscribirme
                </button>
              </div>
            </form>
          </div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-danube-400 p-2 ring-1 dark:bg-white/5 dark:ring-white/10">
                <HiCalendarDays
                  aria-hidden="true"
                  className="h-6 w-6 text-white"
                />
              </div>
              <p className="mt-4 font-semibold dark:text-white">
                Artículos semanales
              </p>
              <p className="mt-2 leading-7 dark:text-gray-400">
                Todas las semanas artículos nuevos sobre el mundo de las
                aplicaciones móviles.
              </p>
            </div>

            <div className="flex flex-col items-start">
              <div className="rounded-md bg-danube-400 p-2 ring-1 dark:bg-white/5 dark:ring-white/10">
                <HiNoSymbol aria-hidden="true" className="h-6 w-6 text-white" />
              </div>
              <p className="mt-4 font-semibold dark:text-white">No spam</p>
              <p className="mt-2 leading-7 dark:text-gray-400">
                No te enviaremos spam, solo contenido de calidad. Puedes darte
                de baja cuando quieras.
              </p>
            </div>

            <div className="flex flex-col items-start">
              <div className="rounded-md bg-danube-400 p-2 ring-1 dark:bg-white/5 dark:ring-white/10">
                <HiCheck aria-hidden="true" className="h-6 w-6 text-white" />
              </div>
              <p className="mt-4 font-semibold dark:text-white">
                Contenido de calidad
              </p>
              <p className="mt-2 leading-7 dark:text-gray-400">
                Nada de contenido generado de manera automática usando ChatGPT.
              </p>
            </div>

            <div className="flex flex-col items-start">
              <div className="rounded-md bg-danube-400 p-2 ring-1 dark:bg-white/5 dark:ring-white/10">
                <HiPencil aria-hidden="true" className="h-6 w-6 text-white" />
              </div>
              <p className="mt-4 font-semibold dark:text-white">
                Recomendaciones
              </p>
              <p className="mt-2 leading-7 dark:text-gray-400">
                Tips indispensables sobre mejores prácticas y metodologías.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscribeSection
