import { Link } from "gatsby"
import React from "react"
import { useActiveHash } from "../hooks"
import { cn } from "../utils"

type TableOfContents = {
  items: TocItem[]
}

type TocItem = {
  items: TocItem[]
  title: string
  url: string
}

type Props = {
  slug: string
  items: Record<string, unknown>
}

const Toc = ({ slug, items }: Props): JSX.Element => {
  if (items.items && typeof items.items === "object") {
    const toc = items as TableOfContents

    if (toc.items.length < 1) {
      return <></>
    }

    if (toc.items[0].title != "Introducción") {
      const intro: TocItem = {
        url: `#${slug}`,
        title: "Introducción",
        items: [],
      }
      toc.items.unshift(intro)
    }

    const ids = getIds(toc.items)
    const activeId = useActiveHash(ids)

    return (
      <div className="not-prose px-2">
        <h5 className="text-base font-semibold uppercase text-primary">
          En este artículo
        </h5>
        {renderItems(toc.items, activeId, 0)}
      </div>
    )
  } else {
    return <></>
  }
}

export default Toc

function renderItems(items: TocItem[], activeId: string, depth: number) {
  return (
    <ul className="m-0 list-none p-0">
      {items.map((item, index) => {
        return (
          <li key={index} className={cn("m-0 py-0", depth > 0 && "pl-4")}>
            {item.url && (
              <Link
                to={item.url}
                className={cn(
                  "text-base font-normal no-underline",
                  activeId === item.url.slice(1)
                    ? "font-semibold text-pink-600 dark:text-pink-400"
                    : "text-primary hover:text-pink-600 dark:hover:text-pink-400"
                )}
              >
                {item.title}
              </Link>
            )}
            {item.items && renderItems(item.items, activeId, depth + 1)}
          </li>
        )
      })}
    </ul>
  )
}

function getIds(items: TocItem[]) {
  return items.reduce<string[]>((acc, item) => {
    if (item.url) {
      // url has a # as first character, remove it to get the raw CSS-id
      acc.push(item.url.slice(1))
    }
    if (item.items) {
      acc.push(...getIds(item.items))
    }
    return acc
  }, [])
}
