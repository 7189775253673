import { useEffect } from "react"
import { useDarkMode } from "usehooks-ts"

type UseThemeModeOutput = {
  isDarkMode: boolean
  toggle: () => void
}

function useThemeMode(): UseThemeModeOutput {
  const { isDarkMode, toggle } = useDarkMode()

  const rawSetTheme = (isDarkMode: boolean) => {
    const oldTheme = isDarkMode ? "light" : "dark"
    const newTheme = isDarkMode ? "dark" : "light"

    const root = window.document.documentElement
    root.classList.remove(oldTheme)
    root.classList.add(newTheme)
  }

  useEffect(() => {
    rawSetTheme(isDarkMode)
  }, [isDarkMode])

  return {
    isDarkMode,
    toggle,
  }
}

export default useThemeMode
