import { graphql, useStaticQuery } from "gatsby"

export const useAllSponsors = () => {
  const data = useStaticQuery<Queries.AllSponsorsStaticQuery>(graphql`
    query AllSponsorsStatic {
      ...AllSponsors
    }
  `)

  return data
}
