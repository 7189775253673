import React from "react"
import { FaRssSquare } from "@react-icons/all-files/fa/FaRssSquare"
import { FaSquareXTwitter } from "@react-icons/all-files/fa6/FaSquareXTwitter"
import { SubscribeSection } from "../components"

type FooterProps = {
  title: string
  description: string
}

const currentYear = new Date().getFullYear()

const Footer = (props: FooterProps): JSX.Element => {
  return (
    <footer className="sticky top-[100vh] text-gray-600">
      <div className="bg-primary">
        <SubscribeSection description={props.description} />
        <div className="container mx-auto flex flex-col flex-wrap px-5 py-4 sm:flex-row">
          <p className="text-center text-sm text-primary sm:text-left">
            © {currentYear} {props.title}
          </p>

          <span className="mt-2 inline-flex justify-center gap-3 sm:ml-auto sm:mt-0 sm:justify-start">
            <a
              className="text-primary"
              href="https://twitter.com/asynclearn/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Go to the AsyncLearn Twitter account"
            >
              <FaSquareXTwitter className="h-5 w-5 hover:text-[#1d9bf0]" />
            </a>

            <a
              className="text-primary"
              href="https://asynclearn.com/rss.xml"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Go to the AsyncLearn RSS feed"
            >
              <FaRssSquare className="h-5 w-5 hover:text-orange-600" />
            </a>
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
