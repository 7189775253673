import React from "react"
import { useAllSponsors } from "../hooks"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const SponsorCard = () => {

  const allSponsors = useAllSponsors()
  const sponsor = allSponsors.allSponsorYaml.nodes.at(0)

  if (!sponsor || !sponsor.enabled) {
    return <></>
  }

  const image = getImage(sponsor.image)

  return (
    <section className="not-prose flex flex-col items-center justify-between gap-4 rounded-xl border p-4 md:flex-row md:gap-8 md:p-8">
      <div className="flex-shrink-0">
        {image && (
          <GatsbyImage
            image={image}
            alt="Sponsor image"
            className="size-24 rounded-lg object-cover"
          />
        )}
      </div>
      <div className="flex-1 space-y-2 md:space-y-4">
        <div className="flex items-center space-x-2">
          <h2 className="text-lg font-semibold md:text-xl">{sponsor.title}</h2>
          <span className="text-xs text-pink-600 dark:text-pink-400 md:text-sm">
            Sponsor
          </span>
        </div>
        <div>
          <p className="text-sm md:text-base">
            {sponsor.description}
          </p>

          {sponsor.url && sponsor.cta && (
            <div className="mt-4 text-center md:mt-2 md:text-right">
              <a
                href={sponsor.url ?? ""}
                rel="noopener noreferrer"
                target="_blank"
                className="flex-none rounded-md bg-danube-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-danube-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-danube-500"
              >
                <span>{sponsor.cta}</span>
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default SponsorCard
