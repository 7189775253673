import { Link as GatsbyLink } from "gatsby"
import React from "react"
import Badge from "./badge"
import { Tag } from "../queries"

type Props = {
  slug: string | null
  title: string | null
  date: string | null
  authorName: string | null
  excerpt: string | null
  tags: Tag[] | null
}

const ArticleCard = ({
  slug,
  title,
  date,
  authorName,
  excerpt,
  tags,
}: Props): JSX.Element => {
  return (
    <article className="block h-full rounded-xl border border-gray-200 shadow-xl transition hover:border-danube-500/10 hover:shadow-danube-500/10 dark:border-gray-800 dark:hover:border-gray-500/10 dark:hover:shadow-gray-500/10">
      <div className="p-6">
        <header className="mb-4">
          <GatsbyLink to={`/blog/${slug}`}>
            <h3 className="overflow-hidden text-ellipsis text-xl font-extrabold text-secondary hover:text-danube-500 dark:hover:text-danube-300">
              {title}
            </h3>
          </GatsbyLink>
          <p className="text-sm text-primary">
            Por {authorName}, {date}
          </p>
          <div className="mt-1">
            {tags?.map((tag) => (
              <GatsbyLink
                key={tag.id}
                to={`/blog/tags/${tag.fields.slug}/`}
                className="mr-2 no-underline"
              >
                <Badge color={tag.color} isClickable>
                  {tag.name}
                </Badge>
              </GatsbyLink>
            ))}
          </div>
        </header>
        <p className="overflow-hidden text-ellipsis">{excerpt}</p>
      </div>
    </article>
  )
}

export default ArticleCard
