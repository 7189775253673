import { HiCalendarDays } from "@react-icons/all-files/hi2/HiCalendarDays"
import { HiOutlineClock } from "@react-icons/all-files/hi2/HiOutlineClock"
import { Link as GatsbyLink } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Badge from "./badge"
import SponsorCard from "./sponsorCard"

const ArticleHeader = ({ mdx }: Queries.BlogPostQuery) => {
  const tags = mdx?.frontmatter?.tags ?? []
  const date = mdx?.frontmatter?.dateES ?? ""
  const timeToReadInMinutes = mdx?.fields?.timeToRead?.minutes ?? 0
  const slug = mdx?.frontmatter?.slug ?? ""
  const author = mdx?.frontmatter?.author

  return (
    <>
      <TagsInfo tags={tags} />

      <h1 id={slug} className="text-pretty text-center">
        {mdx?.frontmatter?.title}
      </h1>

      <div className="-mt-6 mb-6 flex flex-col content-center items-center justify-center space-x-2 sm:flex-row">
        {author && <AuthorInfo author={author} />}
        <TimeInfo date={date} minutes={timeToReadInMinutes} />
      </div>

      <SponsorCard />
    </>
  )
}

export default ArticleHeader

function TagsInfo({
  tags,
}: {
  tags: ReadonlyArray<Queries.TagsFragment | null>
}) {
  return (
    <div className="flex items-center justify-center space-x-2">
      {tags
        .filter((e): e is Exclude<typeof e, null> => e !== null)
        .map((tag) => (
          <GatsbyLink
            key={tag.id}
            to={`/blog/tags/${tag?.fields?.slug}/`}
            className="no-underline"
          >
            <Badge color={tag.color ?? undefined} isClickable={true}>
              {tag?.name}
            </Badge>
          </GatsbyLink>
        ))}
    </div>
  )
}

function TimeInfo({ date, minutes }: { date: string; minutes: number }) {
  return (
    <p className="mt-2 space-x-2 text-base">
      <span className="inline-flex items-baseline capitalize">
        <HiCalendarDays className="mx-1 size-5 self-center" />
        {date}
      </span>
      <span className="inline-flex items-baseline">
        <HiOutlineClock className="mx-1 size-5 self-center" />
        {Math.ceil(minutes)}min de lectura
      </span>
    </p>
  )
}

function AuthorInfo({ author }: { author: Queries.AuthorFragment }) {
  const photo = getImage(author.photo)

  return (
    <GatsbyLink to={`/blog/author/${author.yamlId}/`} className="group block flex-shrink-0 no-underline">
      <div className="flex items-center">
        {photo && (
          <div>
            <GatsbyImage
              image={photo}
              className="inline-block size-10 rounded-full"
              alt="author profile picture"
            />
          </div>
        )}
        <div className="ml-3">
          <p className="text-base font-medium text-primary group-hover:text-secondary">
            {author.name}
          </p>
        </div>
      </div>
    </GatsbyLink>
  )
}
