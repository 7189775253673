const copyToClipboard = (content: string) => {
  const clipboard = window.navigator.clipboard
  /*
   * fallback to older browsers (including Safari)
   * if clipboard API not supported
   */
  if (!clipboard || typeof clipboard.writeText !== `function`) {
    const textArea = document.createElement(`textarea`)
    textArea.value = content
    textArea.setAttribute(`readonly`, "true")
    textArea.setAttribute(`contenteditable`, "true")
    textArea.style.position = `absolute`
    textArea.style.left = `-9999px`
    document.body.appendChild(textArea)
    textArea.select()

    const range = document.createRange()
    const sel = window.getSelection()

    sel?.removeAllRanges()
    sel?.addRange(range)

    textArea.setSelectionRange(0, textArea.value.length)

    document.execCommand(`copy`)
    document.body.removeChild(textArea)

    return Promise.resolve(true)
  }

  return clipboard.writeText(content)
}

export default copyToClipboard
