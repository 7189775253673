import _ from "lodash"
import React from "react"
import { mapTag } from "../utils"
import ArticleCard from "./articleCard"
import Pagination from "./pagination"

type Props = {
  articles: Queries.AllMdxFragment
  currentPage: number
  totalPages: number
  pageSize: number
  path: string
}

const ArticleList = ({
  articles,
  currentPage,
  totalPages,
  pageSize,
  path,
}: Props): JSX.Element => {
  return (
    <section className="container mx-auto p-6">
      <ul className="grid grid-cols-1 gap-5 md:grid-cols-3">
        {articles.allMdx.edges.map(({ node }) => (
          <li key={node.id}>
            {node.frontmatter !== null && (
              <ArticleCard
                slug={node.frontmatter.slug}
                title={node.frontmatter.title}
                date={node.frontmatter.date}
                authorName={node.frontmatter.author?.name ?? ""}
                excerpt={
                  node.frontmatter.excerpt?.slice(0, 149).trim().concat("…") ??
                  node.excerpt
                }
                tags={_.compact(node.frontmatter.tags).flatMap((tag) => {
                  return mapTag(
                    tag.id,
                    tag.yamlId,
                    tag.name,
                    tag.color,
                    tag.fields?.slug
                  )
                })}
              />
            )}
          </li>
        ))}
      </ul>
      <Pagination
        path={path}
        currentPage={currentPage}
        totalCount={totalPages}
        pageSize={pageSize}
      />
    </section>
  )
}

export default ArticleList
