import React from "react"

const Cta = () => {
  return (
    <div className="">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden px-6 py-16 sm:px-24 xl:py-32">
          <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight dark:text-white sm:text-4xl">
            ¿Ya estás subscrito a nuestro newsletter?
          </h2>
          <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 dark:text-gray-300">
            Enviamos una recopilación mensual de artículos que hemos escrito,
            así como contenido creado por la comunidad: podcasts, libros, entre
            otros.
          </p>
          <form
            className="mx-auto mt-10 flex max-w-md gap-x-4"
            action="https://asynclearn.us11.list-manage.com/subscribe/post?u=37c43fca48bde7debeba695b7&amp;id=a13445c3ca&amp;f_id=009ea8e0f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            target="_blank"
            noValidate
          >
            <label htmlFor="mce-EMAIL" className="sr-only">
              Correo electrónico
            </label>
            <input
              id="mce-EMAIL"
              name="EMAIL"
              type="email"
              required
              placeholder="Introduce tu correo electrónico"
              autoComplete="email"
              className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 dark:text-white shadow-sm ring-1 ring-inset dark:ring-white/10 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
            />
            <button
              type="submit"
              className="flex-none rounded-md bg-danube-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-danube-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-danube-500"
            >
              Subscribirme
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Cta
