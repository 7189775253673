import React, { Children, Fragment, HTMLProps } from "react"
import { MDXProvider } from "@mdx-js/react"
import PrismSyntaxHighlight from "./prismSyntaxHighlight"
import Quote from "./quote"

const MDXRenderer = ({ body }: { body: undefined }) => {
  return (
    <MDXProvider
      components={{
        pre: (props) => <Fragment {...props} />,
        code: (props: HTMLProps<HTMLElement>) => {
          return props.className ? (
            <PrismSyntaxHighlight
              className={props.className}
              code={childrenToString(props.children)}
            />
          ) : (
            <code>{props.children}</code>
          )
        },
        img: (props) => (
          <img
            {...props}
            className="mx-auto rounded-md border border-danube-400 dark:border-white"
          />
        ),
        Quote
      }}
    >
      {body}
    </MDXProvider>
  )
}

export default MDXRenderer

const childrenToString = (
  children: HTMLProps<HTMLElement>["children"]
): string => {
  let label = ""

  Children.map(children, (child) => {
    if (typeof child === "string") {
      label += child
    }
  })

  return label
}
